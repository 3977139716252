import React, { useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  AppContext,
  enableCompare,
  disableCompare,
  doFetchStockStatus,
} from '../../config/context'
import { parseSeo } from '../../helpers/seo'
import { SPACING } from '../../helpers/constants'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Content } from '../../components/Content'
import { Text } from '../../components/Text'
import { Container } from '../../components/Container'
import { Grid } from '../../components/Grid'
import { ProductCard } from '../../components/ProductCard'
import { PageContent } from '../../components/PageContent'
import { Filter } from '../../components/Filter'
import { hasFilterMatch, hasColorFilterMatch } from '../../helpers/filter'

export default ({ data }) => {
  const [state, dispatch] = useContext(AppContext)
  const { compareIsEnabled, products } = state

  const productCategory = data.wordpress.productCategory
  const { id, name, description, seo } = productCategory
  const filters = productCategory.acfFilterChoices.filterChoices
  const colors =
    data.wordpress.paColors && data.wordpress.paColors.nodes
      ? data.wordpress.paColors.nodes
      : null

  const toggleCompare = () => {
    !compareIsEnabled ? dispatch(enableCompare()) : dispatch(disableCompare())
  }

  const productsInCategory = products.filter(product => {
    if (
      product.productCategories &&
      product.productCategories.nodes &&
      product.productCategories.nodes.length > 0
    ) {
      let categoryIds = product.productCategories.nodes.map(
        category => category.id
      )
      return categoryIds.includes(id)
    } else {
      return false
    }
  })

  /**
   * Fetch stock status
   */
  useEffect(() => {
    dispatch(doFetchStockStatus())
  }, [])

  const [activeFilterIDs, setActiveFilterIDs] = useState([])
  const [activeColorFilterIDs, setActiveColorFilterIDs] = useState([])

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <IndentWrapper col={3}>
            <Content>
              <Heading type={'h1'} size={1}>
                {name}
              </Heading>
              {description && <Text columns={2}>{description}</Text>}
            </Content>
            <Divider size={SPACING.MEDIUM} />
            <Filter
              toggleCompare={toggleCompare}
              filters={filters}
              colors={colors}
              activeFilterIDs={activeFilterIDs}
              setActiveFilterIDs={setActiveFilterIDs}
              activeColorFilterIDs={activeColorFilterIDs}
              setActiveColorFilterIDs={setActiveColorFilterIDs}
            />
          </IndentWrapper>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />

          <Grid col={4} mobileCol={2}>
            {productsInCategory.map((product, index) => {
              return (
                hasFilterMatch(product, filters, activeFilterIDs) &&
                hasColorFilterMatch(product, activeColorFilterIDs) && (
                  <ProductCard
                    key={product.id}
                    product={product}
                    compareAllowed
                  />
                )
              )
            })}
          </Grid>

          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query ProductCategoryListingQuery(
    $id: ID!
    $lang: WordPress_LanguageCodeFilterEnum
  ) {
    wordpress {
      productCategory(id: $id) {
        ...Single_Product_Category
      }
      paColors(where: { language: $lang, orderby: TERM_ID }) {
        nodes {
          name
          id: databaseId
        }
      }
    }
  }
`
